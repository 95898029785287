<template>
  <div class="grid nogutter flex-column">
    <div class="col my-3 text-center">
      <img alt="Capital2Market Logo" src="@/assets/c2m-logo.png" class="logo" />
    </div>
    <div v-if="loading" class="col mt-6 text-center">
      <ProgressSpinner style="width: 100px; height: 100px" strokeWidth="2" />
    </div>

    <div v-if="!loading">
      <div class="grid nogutter flex-column">
        <div class="col pt-4 px-6 text-center">
          <span class="thank-you">Thank you!</span>
        </div>

        <div
          v-if="offeringImage && offeringImage !== ''"
          class="col text-center"
        >
          <img class="offering-img" :src="offeringImage" />
        </div>

        <div class="col py-2 px-6 text-center">
          <span class="thank-you">
            Your investment in {{ offeringName }} is complete.<br />
            Please check your email for any additional information.
          </span>
        </div>
        <div class="col py-2">
          <div class="grid nogutter text-center">
            <div class="col-3"></div>
            <div class="col">
              If your investment made as a joint investment, the secondary
              investor will receive an email to sign their documents. Once all
              documents have been signed the investment can be approved and
              completed.
            </div>
            <div class="col-3"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject, Ref, ref, onMounted, computed } from "vue";
import { useRoute } from "vue-router";
import { Api } from "@/api";
import {
  GetOfferingCompany,
  PostIcon,
  V1ResponseWrapper,
} from "@/api/services/v1Payloads";
import { OfferingCompany } from "@/interfaces/v1/offeringCompany";

export default defineComponent({
  name: "InvestNowInvestmentComplete",
  setup() {
    const route = useRoute();
    const $api = inject("$api") as Api;

    // local
    let offeringId = "";

    // refs
    const loading = ref(false);
    const offeringLoadError = ref(false);
    const logoLoadError = ref(false);

    const offeringCompany: Ref<OfferingCompany | null> = ref(null);
    const offeringImage = ref("");

    // computed
    const offeringName = computed(() => {
      if (offeringCompany.value?.offering_company_name) {
        return offeringCompany.value.offering_company_name;
      }

      return "this offering";
    });

    // api methods
    const loadOfferingCompany = async () => {
      loading.value = true;
      let req: GetOfferingCompany = {
        offering_id: offeringId,
      };
      try {
        let { data }: { data: V1ResponseWrapper } =
          await $api.v1.getOfferingCompany(req);

        if (data.status === "fail") {
          offeringLoadError.value = true;
        }

        offeringCompany.value = data.data as OfferingCompany;

        await loadOfferingLogo();
      } catch (e) {
        console.error(e);
        offeringLoadError.value = true;
        loading.value = false;
      }
    };

    const loadOfferingLogo = async () => {
      if (offeringCompany.value?.file_path) {
        let req: PostIcon = {
          file_path: offeringCompany.value.file_path as string,
        };
        try {
          let { data } = await $api.v1.getOfferingHeader(req);
          offeringImage.value = window.URL.createObjectURL(data);
        } catch (e) {
          console.error(e);
        }
      }

      loading.value = false;
    };

    onMounted(() => {
      offeringId = route.params.id as string;

      loadOfferingCompany();
    });

    return {
      // refs
      loading,
      offeringLoadError,
      logoLoadError,
      // computed
      offeringName,
      offeringImage,
    };
  },
});
</script>

<style lang="scss" scoped>
.logo {
  max-width: 400px;
  max-height: 120px;
  mix-blend-mode: multiply;
}

.thank-you {
  font-size: 24px;
}

.offering-img {
  max-width: 240px;
  max-height: 240px;
  mix-blend-mode: multiply;
}

.joint-disclaimer {
  max-width: 240px;
}
</style>
