
import { defineComponent, inject, Ref, ref, onMounted, computed } from "vue";
import { useRoute } from "vue-router";
import { Api } from "@/api";
import {
  GetOfferingCompany,
  PostIcon,
  V1ResponseWrapper,
} from "@/api/services/v1Payloads";
import { OfferingCompany } from "@/interfaces/v1/offeringCompany";

export default defineComponent({
  name: "InvestNowInvestmentComplete",
  setup() {
    const route = useRoute();
    const $api = inject("$api") as Api;

    // local
    let offeringId = "";

    // refs
    const loading = ref(false);
    const offeringLoadError = ref(false);
    const logoLoadError = ref(false);

    const offeringCompany: Ref<OfferingCompany | null> = ref(null);
    const offeringImage = ref("");

    // computed
    const offeringName = computed(() => {
      if (offeringCompany.value?.offering_company_name) {
        return offeringCompany.value.offering_company_name;
      }

      return "this offering";
    });

    // api methods
    const loadOfferingCompany = async () => {
      loading.value = true;
      let req: GetOfferingCompany = {
        offering_id: offeringId,
      };
      try {
        let { data }: { data: V1ResponseWrapper } =
          await $api.v1.getOfferingCompany(req);

        if (data.status === "fail") {
          offeringLoadError.value = true;
        }

        offeringCompany.value = data.data as OfferingCompany;

        await loadOfferingLogo();
      } catch (e) {
        console.error(e);
        offeringLoadError.value = true;
        loading.value = false;
      }
    };

    const loadOfferingLogo = async () => {
      if (offeringCompany.value?.file_path) {
        let req: PostIcon = {
          file_path: offeringCompany.value.file_path as string,
        };
        try {
          let { data } = await $api.v1.getOfferingHeader(req);
          offeringImage.value = window.URL.createObjectURL(data);
        } catch (e) {
          console.error(e);
        }
      }

      loading.value = false;
    };

    onMounted(() => {
      offeringId = route.params.id as string;

      loadOfferingCompany();
    });

    return {
      // refs
      loading,
      offeringLoadError,
      logoLoadError,
      // computed
      offeringName,
      offeringImage,
    };
  },
});
